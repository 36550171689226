<template>
    <main-body>
        <template #content>
            <div v-for="index in 3" class="py-1">
                <folding-card/>
            </div>
        </template>
    </main-body>
</template>

<script>
import MainBody from "@/components/general/layout/MainBody";
import FoldingCard from "@/components/general/elements/layoutsElements/FoldingCard";

export default {
    name: "DevFoldingCard",
    components: {FoldingCard, MainBody},
    props: {},
    data() {
        return {}
    },
    mounted() {
    },
    methods: {},
    computed: {},
    filters: {},
    watch: {}
}
</script>

<style scoped>

</style>
